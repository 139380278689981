.flb
{
background-size:contain;background-position:50%;background-repeat:no-repeat
}
.fl
{
background-size:contain;background-position:50%;background-repeat:no-repeat;position:relative;display:inline-block;width:1.33333333em;line-height:1em
}
.fl:before
{
content:'\00a0'
}
.fl.fls
{
width:1em
}
.fl-ad
{
background-image:url(../flags/4x3/ad.svg)
}
.fl-ad.fls
{
background-image:url(../flags/1x1/ad.svg)
}
.fl-ae
{
background-image:url(../flags/4x3/ae.svg)
}
.fl-ae.fls
{
background-image:url(../flags/1x1/ae.svg)
}
.fl-af
{
background-image:url(../flags/4x3/af.svg)
}
.fl-af.fls
{
background-image:url(../flags/1x1/af.svg)
}
.fl-ag
{
background-image:url(../flags/4x3/ag.svg)
}
.fl-ag.fls
{
background-image:url(../flags/1x1/ag.svg)
}
.fl-ai
{
background-image:url(../flags/4x3/ai.svg)
}
.fl-ai.fls
{
background-image:url(../flags/1x1/ai.svg)
}
.fl-al
{
background-image:url(../flags/4x3/al.svg)
}
.fl-al.fls
{
background-image:url(../flags/1x1/al.svg)
}
.fl-am
{
background-image:url(../flags/4x3/am.svg)
}
.fl-am.fls
{
background-image:url(../flags/1x1/am.svg)
}
.fl-ao
{
background-image:url(../flags/4x3/ao.svg)
}
.fl-ao.fls
{
background-image:url(../flags/1x1/ao.svg)
}
.fl-aq
{
background-image:url(../flags/4x3/aq.svg)
}
.fl-aq.fls
{
background-image:url(../flags/1x1/aq.svg)
}
.fl-ar
{
background-image:url(../flags/4x3/ar.svg)
}
.fl-ar.fls
{
background-image:url(../flags/1x1/ar.svg)
}
.fl-as
{
background-image:url(../flags/4x3/as.svg)
}
.fl-as.fls
{
background-image:url(../flags/1x1/as.svg)
}
.fl-at
{
background-image:url(../flags/4x3/at.svg)
}
.fl-at.fls
{
background-image:url(../flags/1x1/at.svg)
}
.fl-au
{
background-image:url(../flags/4x3/au.svg)
}
.fl-au.fls
{
background-image:url(../flags/1x1/au.svg)
}
.fl-aw
{
background-image:url(../flags/4x3/aw.svg)
}
.fl-aw.fls
{
background-image:url(../flags/1x1/aw.svg)
}
.fl-ax
{
background-image:url(../flags/4x3/ax.svg)
}
.fl-ax.fls
{
background-image:url(../flags/1x1/ax.svg)
}
.fl-az
{
background-image:url(../flags/4x3/az.svg)
}
.fl-az.fls
{
background-image:url(../flags/1x1/az.svg)
}
.fl-ba
{
background-image:url(../flags/4x3/ba.svg)
}
.fl-ba.fls
{
background-image:url(../flags/1x1/ba.svg)
}
.fl-bb
{
background-image:url(../flags/4x3/bb.svg)
}
.fl-bb.fls
{
background-image:url(../flags/1x1/bb.svg)
}
.fl-bd
{
background-image:url(../flags/4x3/bd.svg)
}
.fl-bd.fls
{
background-image:url(../flags/1x1/bd.svg)
}
.fl-be
{
background-image:url(../flags/4x3/be.svg)
}
.fl-be.fls
{
background-image:url(../flags/1x1/be.svg)
}
.fl-bf
{
background-image:url(../flags/4x3/bf.svg)
}
.fl-bf.fls
{
background-image:url(../flags/1x1/bf.svg)
}
.fl-bg
{
background-image:url(../flags/4x3/bg.svg)
}
.fl-bg.fls
{
background-image:url(../flags/1x1/bg.svg)
}
.fl-bh
{
background-image:url(../flags/4x3/bh.svg)
}
.fl-bh.fls
{
background-image:url(../flags/1x1/bh.svg)
}
.fl-bi
{
background-image:url(../flags/4x3/bi.svg)
}
.fl-bi.fls
{
background-image:url(../flags/1x1/bi.svg)
}
.fl-bj
{
background-image:url(../flags/4x3/bj.svg)
}
.fl-bj.fls
{
background-image:url(../flags/1x1/bj.svg)
}
.fl-bl
{
background-image:url(../flags/4x3/bl.svg)
}
.fl-bl.fls
{
background-image:url(../flags/1x1/bl.svg)
}
.fl-bm
{
background-image:url(../flags/4x3/bm.svg)
}
.fl-bm.fls
{
background-image:url(../flags/1x1/bm.svg)
}
.fl-bn
{
background-image:url(../flags/4x3/bn.svg)
}
.fl-bn.fls
{
background-image:url(../flags/1x1/bn.svg)
}
.fl-bo
{
background-image:url(../flags/4x3/bo.svg)
}
.fl-bo.fls
{
background-image:url(../flags/1x1/bo.svg)
}
.fl-bq
{
background-image:url(../flags/4x3/bq.svg)
}
.fl-bq.fls
{
background-image:url(../flags/1x1/bq.svg)
}
.fl-br
{
background-image:url(../flags/4x3/br.svg)
}
.fl-br.fls
{
background-image:url(../flags/1x1/br.svg)
}
.fl-bs
{
background-image:url(../flags/4x3/bs.svg)
}
.fl-bs.fls
{
background-image:url(../flags/1x1/bs.svg)
}
.fl-bt
{
background-image:url(../flags/4x3/bt.svg)
}
.fl-bt.fls
{
background-image:url(../flags/1x1/bt.svg)
}
.fl-bv
{
background-image:url(../flags/4x3/bv.svg)
}
.fl-bv.fls
{
background-image:url(../flags/1x1/bv.svg)
}
.fl-bw
{
background-image:url(../flags/4x3/bw.svg)
}
.fl-bw.fls
{
background-image:url(../flags/1x1/bw.svg)
}
.fl-by
{
background-image:url(../flags/4x3/by.svg)
}
.fl-by.fls
{
background-image:url(../flags/1x1/by.svg)
}
.fl-bz
{
background-image:url(../flags/4x3/bz.svg)
}
.fl-bz.fls
{
background-image:url(../flags/1x1/bz.svg)
}
.fl-ca
{
background-image:url(../flags/4x3/ca.svg)
}
.fl-ca.fls
{
background-image:url(../flags/1x1/ca.svg)
}
.fl-cc
{
background-image:url(../flags/4x3/cc.svg)
}
.fl-cc.fls
{
background-image:url(../flags/1x1/cc.svg)
}
.fl-cd
{
background-image:url(../flags/4x3/cd.svg)
}
.fl-cd.fls
{
background-image:url(../flags/1x1/cd.svg)
}
.fl-cf
{
background-image:url(../flags/4x3/cf.svg)
}
.fl-cf.fls
{
background-image:url(../flags/1x1/cf.svg)
}
.fl-cg
{
background-image:url(../flags/4x3/cg.svg)
}
.fl-cg.fls
{
background-image:url(../flags/1x1/cg.svg)
}
.fl-ch
{
background-image:url(../flags/4x3/ch.svg)
}
.fl-ch.fls
{
background-image:url(../flags/1x1/ch.svg)
}
.fl-ci
{
background-image:url(../flags/4x3/ci.svg)
}
.fl-ci.fls
{
background-image:url(../flags/1x1/ci.svg)
}
.fl-ck
{
background-image:url(../flags/4x3/ck.svg)
}
.fl-ck.fls
{
background-image:url(../flags/1x1/ck.svg)
}
.fl-cl
{
background-image:url(../flags/4x3/cl.svg)
}
.fl-cl.fls
{
background-image:url(../flags/1x1/cl.svg)
}
.fl-cm
{
background-image:url(../flags/4x3/cm.svg)
}
.fl-cm.fls
{
background-image:url(../flags/1x1/cm.svg)
}
.fl-cn
{
background-image:url(../flags/4x3/cn.svg)
}
.fl-cn.fls
{
background-image:url(../flags/1x1/cn.svg)
}
.fl-co
{
background-image:url(../flags/4x3/co.svg)
}
.fl-co.fls
{
background-image:url(../flags/1x1/co.svg)
}
.fl-cr
{
background-image:url(../flags/4x3/cr.svg)
}
.fl-cr.fls
{
background-image:url(../flags/1x1/cr.svg)
}
.fl-cu
{
background-image:url(../flags/4x3/cu.svg)
}
.fl-cu.fls
{
background-image:url(../flags/1x1/cu.svg)
}
.fl-cv
{
background-image:url(../flags/4x3/cv.svg)
}
.fl-cv.fls
{
background-image:url(../flags/1x1/cv.svg)
}
.fl-cw
{
background-image:url(../flags/4x3/cw.svg)
}
.fl-cw.fls
{
background-image:url(../flags/1x1/cw.svg)
}
.fl-cx
{
background-image:url(../flags/4x3/cx.svg)
}
.fl-cx.fls
{
background-image:url(../flags/1x1/cx.svg)
}
.fl-cy
{
background-image:url(../flags/4x3/cy.svg)
}
.fl-cy.fls
{
background-image:url(../flags/1x1/cy.svg)
}
.fl-cz
{
background-image:url(../flags/4x3/cz.svg)
}
.fl-cz.fls
{
background-image:url(../flags/1x1/cz.svg)
}
.fl-de
{
background-image:url(../flags/4x3/de.svg)
}
.fl-de.fls
{
background-image:url(../flags/1x1/de.svg)
}
.fl-dj
{
background-image:url(../flags/4x3/dj.svg)
}
.fl-dj.fls
{
background-image:url(../flags/1x1/dj.svg)
}
.fl-dk
{
background-image:url(../flags/4x3/dk.svg)
}
.fl-dk.fls
{
background-image:url(../flags/1x1/dk.svg)
}
.fl-dm
{
background-image:url(../flags/4x3/dm.svg)
}
.fl-dm.fls
{
background-image:url(../flags/1x1/dm.svg)
}
.fl-do
{
background-image:url(../flags/4x3/do.svg)
}
.fl-do.fls
{
background-image:url(../flags/1x1/do.svg)
}
.fl-dz
{
background-image:url(../flags/4x3/dz.svg)
}
.fl-dz.fls
{
background-image:url(../flags/1x1/dz.svg)
}
.fl-ec
{
background-image:url(../flags/4x3/ec.svg)
}
.fl-ec.fls
{
background-image:url(../flags/1x1/ec.svg)
}
.fl-ee
{
background-image:url(../flags/4x3/ee.svg)
}
.fl-ee.fls
{
background-image:url(../flags/1x1/ee.svg)
}
.fl-eg
{
background-image:url(../flags/4x3/eg.svg)
}
.fl-eg.fls
{
background-image:url(../flags/1x1/eg.svg)
}
.fl-eh
{
background-image:url(../flags/4x3/eh.svg)
}
.fl-eh.fls
{
background-image:url(../flags/1x1/eh.svg)
}
.fl-er
{
background-image:url(../flags/4x3/er.svg)
}
.fl-er.fls
{
background-image:url(../flags/1x1/er.svg)
}
.fl-es
{
background-image:url(../flags/4x3/es.svg)
}
.fl-es.fls
{
background-image:url(../flags/1x1/es.svg)
}
.fl-et
{
background-image:url(../flags/4x3/et.svg)
}
.fl-et.fls
{
background-image:url(../flags/1x1/et.svg)
}
.fl-fl
{
background-image:url(../flags/4x3/fi.svg)
}
.fl-fl.fls
{
background-image:url(../flags/1x1/fi.svg)
}
.fl-fj
{
background-image:url(../flags/4x3/fj.svg)
}
.fl-fj.fls
{
background-image:url(../flags/1x1/fj.svg)
}
.fl-fk
{
background-image:url(../flags/4x3/fk.svg)
}
.fl-fk.fls
{
background-image:url(../flags/1x1/fk.svg)
}
.fl-fm
{
background-image:url(../flags/4x3/fm.svg)
}
.fl-fm.fls
{
background-image:url(../flags/1x1/fm.svg)
}
.fl-fo
{
background-image:url(../flags/4x3/fo.svg)
}
.fl-fo.fls
{
background-image:url(../flags/1x1/fo.svg)
}
.fl-fr
{
background-image:url(../flags/4x3/fr.svg)
}
.fl-fr.fls
{
background-image:url(../flags/1x1/fr.svg)
}
.fl-ga
{
background-image:url(../flags/4x3/ga.svg)
}
.fl-ga.fls
{
background-image:url(../flags/1x1/ga.svg)
}
.fl-gb
{
background-image:url(../flags/4x3/gb.svg)
}
.fl-gb.fls
{
background-image:url(../flags/1x1/gb.svg)
}
.fl-gd
{
background-image:url(../flags/4x3/gd.svg)
}
.fl-gd.fls
{
background-image:url(../flags/1x1/gd.svg)
}
.fl-ge
{
background-image:url(../flags/4x3/ge.svg)
}
.fl-ge.fls
{
background-image:url(../flags/1x1/ge.svg)
}
.fl-gf
{
background-image:url(../flags/4x3/gf.svg)
}
.fl-gf.fls
{
background-image:url(../flags/1x1/gf.svg)
}
.fl-gg
{
background-image:url(../flags/4x3/gg.svg)
}
.fl-gg.fls
{
background-image:url(../flags/1x1/gg.svg)
}
.fl-gh
{
background-image:url(../flags/4x3/gh.svg)
}
.fl-gh.fls
{
background-image:url(../flags/1x1/gh.svg)
}
.fl-gi
{
background-image:url(../flags/4x3/gi.svg)
}
.fl-gi.fls
{
background-image:url(../flags/1x1/gi.svg)
}
.fl-gl
{
background-image:url(../flags/4x3/gl.svg)
}
.fl-gl.fls
{
background-image:url(../flags/1x1/gl.svg)
}
.fl-gm
{
background-image:url(../flags/4x3/gm.svg)
}
.fl-gm.fls
{
background-image:url(../flags/1x1/gm.svg)
}
.fl-gn
{
background-image:url(../flags/4x3/gn.svg)
}
.fl-gn.fls
{
background-image:url(../flags/1x1/gn.svg)
}
.fl-gp
{
background-image:url(../flags/4x3/gp.svg)
}
.fl-gp.fls
{
background-image:url(../flags/1x1/gp.svg)
}
.fl-gq
{
background-image:url(../flags/4x3/gq.svg)
}
.fl-gq.fls
{
background-image:url(../flags/1x1/gq.svg)
}
.fl-gr
{
background-image:url(../flags/4x3/gr.svg)
}
.fl-gr.fls
{
background-image:url(../flags/1x1/gr.svg)
}
.fl-gs
{
background-image:url(../flags/4x3/gs.svg)
}
.fl-gs.fls
{
background-image:url(../flags/1x1/gs.svg)
}
.fl-gt
{
background-image:url(../flags/4x3/gt.svg)
}
.fl-gt.fls
{
background-image:url(../flags/1x1/gt.svg)
}
.fl-gu
{
background-image:url(../flags/4x3/gu.svg)
}
.fl-gu.fls
{
background-image:url(../flags/1x1/gu.svg)
}
.fl-gw
{
background-image:url(../flags/4x3/gw.svg)
}
.fl-gw.fls
{
background-image:url(../flags/1x1/gw.svg)
}
.fl-gy
{
background-image:url(../flags/4x3/gy.svg)
}
.fl-gy.fls
{
background-image:url(../flags/1x1/gy.svg)
}
.fl-hk
{
background-image:url(../flags/4x3/hk.svg)
}
.fl-hk.fls
{
background-image:url(../flags/1x1/hk.svg)
}
.fl-hm
{
background-image:url(../flags/4x3/hm.svg)
}
.fl-hm.fls
{
background-image:url(../flags/1x1/hm.svg)
}
.fl-hn
{
background-image:url(../flags/4x3/hn.svg)
}
.fl-hn.fls
{
background-image:url(../flags/1x1/hn.svg)
}
.fl-hr
{
background-image:url(../flags/4x3/hr.svg)
}
.fl-hr.fls
{
background-image:url(../flags/1x1/hr.svg)
}
.fl-ht
{
background-image:url(../flags/4x3/ht.svg)
}
.fl-ht.fls
{
background-image:url(../flags/1x1/ht.svg)
}
.fl-hu
{
background-image:url(../flags/4x3/hu.svg)
}
.fl-hu.fls
{
background-image:url(../flags/1x1/hu.svg)
}
.fl-id
{
background-image:url(../flags/4x3/id.svg)
}
.fl-id.fls
{
background-image:url(../flags/1x1/id.svg)
}
.fl-ie
{
background-image:url(../flags/4x3/ie.svg)
}
.fl-ie.fls
{
background-image:url(../flags/1x1/ie.svg)
}
.fl-il
{
background-image:url(../flags/4x3/il.svg)
}
.fl-il.fls
{
background-image:url(../flags/1x1/il.svg)
}
.fl-im
{
background-image:url(../flags/4x3/im.svg)
}
.fl-im.fls
{
background-image:url(../flags/1x1/im.svg)
}
.fl-in
{
background-image:url(../flags/4x3/in.svg)
}
.fl-in.fls
{
background-image:url(../flags/1x1/in.svg)
}
.fl-io
{
background-image:url(../flags/4x3/io.svg)
}
.fl-io.fls
{
background-image:url(../flags/1x1/io.svg)
}
.fl-iq
{
background-image:url(../flags/4x3/iq.svg)
}
.fl-iq.fls
{
background-image:url(../flags/1x1/iq.svg)
}
.fl-ir
{
background-image:url(../flags/4x3/ir.svg)
}
.fl-ir.fls
{
background-image:url(../flags/1x1/ir.svg)
}
.fl-is
{
background-image:url(../flags/4x3/is.svg)
}
.fl-is.fls
{
background-image:url(../flags/1x1/is.svg)
}
.fl-it
{
background-image:url(../flags/4x3/it.svg)
}
.fl-it.fls
{
background-image:url(../flags/1x1/it.svg)
}
.fl-je
{
background-image:url(../flags/4x3/je.svg)
}
.fl-je.fls
{
background-image:url(../flags/1x1/je.svg)
}
.fl-jm
{
background-image:url(../flags/4x3/jm.svg)
}
.fl-jm.fls
{
background-image:url(../flags/1x1/jm.svg)
}
.fl-jo
{
background-image:url(../flags/4x3/jo.svg)
}
.fl-jo.fls
{
background-image:url(../flags/1x1/jo.svg)
}
.fl-jp
{
background-image:url(../flags/4x3/jp.svg)
}
.fl-jp.fls
{
background-image:url(../flags/1x1/jp.svg)
}
.fl-ke
{
background-image:url(../flags/4x3/ke.svg)
}
.fl-ke.fls
{
background-image:url(../flags/1x1/ke.svg)
}
.fl-kg
{
background-image:url(../flags/4x3/kg.svg)
}
.fl-kg.fls
{
background-image:url(../flags/1x1/kg.svg)
}
.fl-kh
{
background-image:url(../flags/4x3/kh.svg)
}
.fl-kh.fls
{
background-image:url(../flags/1x1/kh.svg)
}
.fl-ki
{
background-image:url(../flags/4x3/ki.svg)
}
.fl-ki.fls
{
background-image:url(../flags/1x1/ki.svg)
}
.fl-km
{
background-image:url(../flags/4x3/km.svg)
}
.fl-km.fls
{
background-image:url(../flags/1x1/km.svg)
}
.fl-kn
{
background-image:url(../flags/4x3/kn.svg)
}
.fl-kn.fls
{
background-image:url(../flags/1x1/kn.svg)
}
.fl-kp
{
background-image:url(../flags/4x3/kp.svg)
}
.fl-kp.fls
{
background-image:url(../flags/1x1/kp.svg)
}
.fl-kr
{
background-image:url(../flags/4x3/kr.svg)
}
.fl-kr.fls
{
background-image:url(../flags/1x1/kr.svg)
}
.fl-kw
{
background-image:url(../flags/4x3/kw.svg)
}
.fl-kw.fls
{
background-image:url(../flags/1x1/kw.svg)
}
.fl-ky
{
background-image:url(../flags/4x3/ky.svg)
}
.fl-ky.fls
{
background-image:url(../flags/1x1/ky.svg)
}
.fl-kz
{
background-image:url(../flags/4x3/kz.svg)
}
.fl-kz.fls
{
background-image:url(../flags/1x1/kz.svg)
}
.fl-la
{
background-image:url(../flags/4x3/la.svg)
}
.fl-la.fls
{
background-image:url(../flags/1x1/la.svg)
}
.fl-lb
{
background-image:url(../flags/4x3/lb.svg)
}
.fl-lb.fls
{
background-image:url(../flags/1x1/lb.svg)
}
.fl-lc
{
background-image:url(../flags/4x3/lc.svg)
}
.fl-lc.fls
{
background-image:url(../flags/1x1/lc.svg)
}
.fl-li
{
background-image:url(../flags/4x3/li.svg)
}
.fl-li.fls
{
background-image:url(../flags/1x1/li.svg)
}
.fl-lk
{
background-image:url(../flags/4x3/lk.svg)
}
.fl-lk.fls
{
background-image:url(../flags/1x1/lk.svg)
}
.fl-lr
{
background-image:url(../flags/4x3/lr.svg)
}
.fl-lr.fls
{
background-image:url(../flags/1x1/lr.svg)
}
.fl-ls
{
background-image:url(../flags/4x3/ls.svg)
}
.fl-ls.fls
{
background-image:url(../flags/1x1/ls.svg)
}
.fl-lt
{
background-image:url(../flags/4x3/lt.svg)
}
.fl-lt.fls
{
background-image:url(../flags/1x1/lt.svg)
}
.fl-lu
{
background-image:url(../flags/4x3/lu.svg)
}
.fl-lu.fls
{
background-image:url(../flags/1x1/lu.svg)
}
.fl-lv
{
background-image:url(../flags/4x3/lv.svg)
}
.fl-lv.fls
{
background-image:url(../flags/1x1/lv.svg)
}
.fl-ly
{
background-image:url(../flags/4x3/ly.svg)
}
.fl-ly.fls
{
background-image:url(../flags/1x1/ly.svg)
}
.fl-ma
{
background-image:url(../flags/4x3/ma.svg)
}
.fl-ma.fls
{
background-image:url(../flags/1x1/ma.svg)
}
.fl-mc
{
background-image:url(../flags/4x3/mc.svg)
}
.fl-mc.fls
{
background-image:url(../flags/1x1/mc.svg)
}
.fl-md
{
background-image:url(../flags/4x3/md.svg)
}
.fl-md.fls
{
background-image:url(../flags/1x1/md.svg)
}
.fl-me
{
background-image:url(../flags/4x3/me.svg)
}
.fl-me.fls
{
background-image:url(../flags/1x1/me.svg)
}
.fl-mf
{
background-image:url(../flags/4x3/mf.svg)
}
.fl-mf.fls
{
background-image:url(../flags/1x1/mf.svg)
}
.fl-mg
{
background-image:url(../flags/4x3/mg.svg)
}
.fl-mg.fls
{
background-image:url(../flags/1x1/mg.svg)
}
.fl-mh
{
background-image:url(../flags/4x3/mh.svg)
}
.fl-mh.fls
{
background-image:url(../flags/1x1/mh.svg)
}
.fl-mk
{
background-image:url(../flags/4x3/mk.svg)
}
.fl-mk.fls
{
background-image:url(../flags/1x1/mk.svg)
}
.fl-ml
{
background-image:url(../flags/4x3/ml.svg)
}
.fl-ml.fls
{
background-image:url(../flags/1x1/ml.svg)
}
.fl-mm
{
background-image:url(../flags/4x3/mm.svg)
}
.fl-mm.fls
{
background-image:url(../flags/1x1/mm.svg)
}
.fl-mn
{
background-image:url(../flags/4x3/mn.svg)
}
.fl-mn.fls
{
background-image:url(../flags/1x1/mn.svg)
}
.fl-mo
{
background-image:url(../flags/4x3/mo.svg)
}
.fl-mo.fls
{
background-image:url(../flags/1x1/mo.svg)
}
.fl-mp
{
background-image:url(../flags/4x3/mp.svg)
}
.fl-mp.fls
{
background-image:url(../flags/1x1/mp.svg)
}
.fl-mq
{
background-image:url(../flags/4x3/mq.svg)
}
.fl-mq.fls
{
background-image:url(../flags/1x1/mq.svg)
}
.fl-mr
{
background-image:url(../flags/4x3/mr.svg)
}
.fl-mr.fls
{
background-image:url(../flags/1x1/mr.svg)
}
.fl-ms
{
background-image:url(../flags/4x3/ms.svg)
}
.fl-ms.fls
{
background-image:url(../flags/1x1/ms.svg)
}
.fl-mt
{
background-image:url(../flags/4x3/mt.svg)
}
.fl-mt.fls
{
background-image:url(../flags/1x1/mt.svg)
}
.fl-mu
{
background-image:url(../flags/4x3/mu.svg)
}
.fl-mu.fls
{
background-image:url(../flags/1x1/mu.svg)
}
.fl-mv
{
background-image:url(../flags/4x3/mv.svg)
}
.fl-mv.fls
{
background-image:url(../flags/1x1/mv.svg)
}
.fl-mw
{
background-image:url(../flags/4x3/mw.svg)
}
.fl-mw.fls
{
background-image:url(../flags/1x1/mw.svg)
}
.fl-mx
{
background-image:url(../flags/4x3/mx.svg)
}
.fl-mx.fls
{
background-image:url(../flags/1x1/mx.svg)
}
.fl-my
{
background-image:url(../flags/4x3/my.svg)
}
.fl-my.fls
{
background-image:url(../flags/1x1/my.svg)
}
.fl-mz
{
background-image:url(../flags/4x3/mz.svg)
}
.fl-mz.fls
{
background-image:url(../flags/1x1/mz.svg)
}
.fl-na
{
background-image:url(../flags/4x3/na.svg)
}
.fl-na.fls
{
background-image:url(../flags/1x1/na.svg)
}
.fl-nc
{
background-image:url(../flags/4x3/nc.svg)
}
.fl-nc.fls
{
background-image:url(../flags/1x1/nc.svg)
}
.fl-ne
{
background-image:url(../flags/4x3/ne.svg)
}
.fl-ne.fls
{
background-image:url(../flags/1x1/ne.svg)
}
.fl-nf
{
background-image:url(../flags/4x3/nf.svg)
}
.fl-nf.fls
{
background-image:url(../flags/1x1/nf.svg)
}
.fl-ng
{
background-image:url(../flags/4x3/ng.svg)
}
.fl-ng.fls
{
background-image:url(../flags/1x1/ng.svg)
}
.fl-ni
{
background-image:url(../flags/4x3/ni.svg)
}
.fl-ni.fls
{
background-image:url(../flags/1x1/ni.svg)
}
.fl-nl
{
background-image:url(../flags/4x3/nl.svg)
}
.fl-nl.fls
{
background-image:url(../flags/1x1/nl.svg)
}
.fl-no
{
background-image:url(../flags/4x3/no.svg)
}
.fl-no.fls
{
background-image:url(../flags/1x1/no.svg)
}
.fl-np
{
background-image:url(../flags/4x3/np.svg)
}
.fl-np.fls
{
background-image:url(../flags/1x1/np.svg)
}
.fl-nr
{
background-image:url(../flags/4x3/nr.svg)
}
.fl-nr.fls
{
background-image:url(../flags/1x1/nr.svg)
}
.fl-nu
{
background-image:url(../flags/4x3/nu.svg)
}
.fl-nu.fls
{
background-image:url(../flags/1x1/nu.svg)
}
.fl-nz
{
background-image:url(../flags/4x3/nz.svg)
}
.fl-nz.fls
{
background-image:url(../flags/1x1/nz.svg)
}
.fl-om
{
background-image:url(../flags/4x3/om.svg)
}
.fl-om.fls
{
background-image:url(../flags/1x1/om.svg)
}
.fl-pa
{
background-image:url(../flags/4x3/pa.svg)
}
.fl-pa.fls
{
background-image:url(../flags/1x1/pa.svg)
}
.fl-pe
{
background-image:url(../flags/4x3/pe.svg)
}
.fl-pe.fls
{
background-image:url(../flags/1x1/pe.svg)
}
.fl-pf
{
background-image:url(../flags/4x3/pf.svg)
}
.fl-pf.fls
{
background-image:url(../flags/1x1/pf.svg)
}
.fl-pg
{
background-image:url(../flags/4x3/pg.svg)
}
.fl-pg.fls
{
background-image:url(../flags/1x1/pg.svg)
}
.fl-ph
{
background-image:url(../flags/4x3/ph.svg)
}
.fl-ph.fls
{
background-image:url(../flags/1x1/ph.svg)
}
.fl-pk
{
background-image:url(../flags/4x3/pk.svg)
}
.fl-pk.fls
{
background-image:url(../flags/1x1/pk.svg)
}
.fl-pl
{
background-image:url(../flags/4x3/pl.svg)
}
.fl-pl.fls
{
background-image:url(../flags/1x1/pl.svg)
}
.fl-pm
{
background-image:url(../flags/4x3/pm.svg)
}
.fl-pm.fls
{
background-image:url(../flags/1x1/pm.svg)
}
.fl-pn
{
background-image:url(../flags/4x3/pn.svg)
}
.fl-pn.fls
{
background-image:url(../flags/1x1/pn.svg)
}
.fl-pr
{
background-image:url(../flags/4x3/pr.svg)
}
.fl-pr.fls
{
background-image:url(../flags/1x1/pr.svg)
}
.fl-ps
{
background-image:url(../flags/4x3/ps.svg)
}
.fl-ps.fls
{
background-image:url(../flags/1x1/ps.svg)
}
.fl-pt
{
background-image:url(../flags/4x3/pt.svg)
}
.fl-pt.fls
{
background-image:url(../flags/1x1/pt.svg)
}
.fl-pw
{
background-image:url(../flags/4x3/pw.svg)
}
.fl-pw.fls
{
background-image:url(../flags/1x1/pw.svg)
}
.fl-py
{
background-image:url(../flags/4x3/py.svg)
}
.fl-py.fls
{
background-image:url(../flags/1x1/py.svg)
}
.fl-qa
{
background-image:url(../flags/4x3/qa.svg)
}
.fl-qa.fls
{
background-image:url(../flags/1x1/qa.svg)
}
.fl-re
{
background-image:url(../flags/4x3/re.svg)
}
.fl-re.fls
{
background-image:url(../flags/1x1/re.svg)
}
.fl-ro
{
background-image:url(../flags/4x3/ro.svg)
}
.fl-ro.fls
{
background-image:url(../flags/1x1/ro.svg)
}
.fl-rs
{
background-image:url(../flags/4x3/rs.svg)
}
.fl-rs.fls
{
background-image:url(../flags/1x1/rs.svg)
}
.fl-ru
{
background-image:url(../flags/4x3/ru.svg)
}
.fl-ru.fls
{
background-image:url(../flags/1x1/ru.svg)
}
.fl-rw
{
background-image:url(../flags/4x3/rw.svg)
}
.fl-rw.fls
{
background-image:url(../flags/1x1/rw.svg)
}
.fl-sa
{
background-image:url(../flags/4x3/sa.svg)
}
.fl-sa.fls
{
background-image:url(../flags/1x1/sa.svg)
}
.fl-sb
{
background-image:url(../flags/4x3/sb.svg)
}
.fl-sb.fls
{
background-image:url(../flags/1x1/sb.svg)
}
.fl-sc
{
background-image:url(../flags/4x3/sc.svg)
}
.fl-sc.fls
{
background-image:url(../flags/1x1/sc.svg)
}
.fl-sd
{
background-image:url(../flags/4x3/sd.svg)
}
.fl-sd.fls
{
background-image:url(../flags/1x1/sd.svg)
}
.fl-se
{
background-image:url(../flags/4x3/se.svg)
}
.fl-se.fls
{
background-image:url(../flags/1x1/se.svg)
}
.fl-sg
{
background-image:url(../flags/4x3/sg.svg)
}
.fl-sg.fls
{
background-image:url(../flags/1x1/sg.svg)
}
.fl-sh
{
background-image:url(../flags/4x3/sh.svg)
}
.fl-sh.fls
{
background-image:url(../flags/1x1/sh.svg)
}
.fl-si
{
background-image:url(../flags/4x3/si.svg)
}
.fl-si.fls
{
background-image:url(../flags/1x1/si.svg)
}
.fl-sj
{
background-image:url(../flags/4x3/sj.svg)
}
.fl-sj.fls
{
background-image:url(../flags/1x1/sj.svg)
}
.fl-sk
{
background-image:url(../flags/4x3/sk.svg)
}
.fl-sk.fls
{
background-image:url(../flags/1x1/sk.svg)
}
.fl-sl
{
background-image:url(../flags/4x3/sl.svg)
}
.fl-sl.fls
{
background-image:url(../flags/1x1/sl.svg)
}
.fl-sm
{
background-image:url(../flags/4x3/sm.svg)
}
.fl-sm.fls
{
background-image:url(../flags/1x1/sm.svg)
}
.fl-sn
{
background-image:url(../flags/4x3/sn.svg)
}
.fl-sn.fls
{
background-image:url(../flags/1x1/sn.svg)
}
.fl-so
{
background-image:url(../flags/4x3/so.svg)
}
.fl-so.fls
{
background-image:url(../flags/1x1/so.svg)
}
.fl-sr
{
background-image:url(../flags/4x3/sr.svg)
}
.fl-sr.fls
{
background-image:url(../flags/1x1/sr.svg)
}
.fl-ss
{
background-image:url(../flags/4x3/ss.svg)
}
.fl-ss.fls
{
background-image:url(../flags/1x1/ss.svg)
}
.fl-st
{
background-image:url(../flags/4x3/st.svg)
}
.fl-st.fls
{
background-image:url(../flags/1x1/st.svg)
}
.fl-sv
{
background-image:url(../flags/4x3/sv.svg)
}
.fl-sv.fls
{
background-image:url(../flags/1x1/sv.svg)
}
.fl-sx
{
background-image:url(../flags/4x3/sx.svg)
}
.fl-sx.fls
{
background-image:url(../flags/1x1/sx.svg)
}
.fl-sy
{
background-image:url(../flags/4x3/sy.svg)
}
.fl-sy.fls
{
background-image:url(../flags/1x1/sy.svg)
}
.fl-sz
{
background-image:url(../flags/4x3/sz.svg)
}
.fl-sz.fls
{
background-image:url(../flags/1x1/sz.svg)
}
.fl-tc
{
background-image:url(../flags/4x3/tc.svg)
}
.fl-tc.fls
{
background-image:url(../flags/1x1/tc.svg)
}
.fl-td
{
background-image:url(../flags/4x3/td.svg)
}
.fl-td.fls
{
background-image:url(../flags/1x1/td.svg)
}
.fl-tf
{
background-image:url(../flags/4x3/tf.svg)
}
.fl-tf.fls
{
background-image:url(../flags/1x1/tf.svg)
}
.fl-tg
{
background-image:url(../flags/4x3/tg.svg)
}
.fl-tg.fls
{
background-image:url(../flags/1x1/tg.svg)
}
.fl-th
{
background-image:url(../flags/4x3/th.svg)
}
.fl-th.fls
{
background-image:url(../flags/1x1/th.svg)
}
.fl-tj
{
background-image:url(../flags/4x3/tj.svg)
}
.fl-tj.fls
{
background-image:url(../flags/1x1/tj.svg)
}
.fl-tk
{
background-image:url(../flags/4x3/tk.svg)
}
.fl-tk.fls
{
background-image:url(../flags/1x1/tk.svg)
}
.fl-tl
{
background-image:url(../flags/4x3/tl.svg)
}
.fl-tl.fls
{
background-image:url(../flags/1x1/tl.svg)
}
.fl-tm
{
background-image:url(../flags/4x3/tm.svg)
}
.fl-tm.fls
{
background-image:url(../flags/1x1/tm.svg)
}
.fl-tn
{
background-image:url(../flags/4x3/tn.svg)
}
.fl-tn.fls
{
background-image:url(../flags/1x1/tn.svg)
}
.fl-to
{
background-image:url(../flags/4x3/to.svg)
}
.fl-to.fls
{
background-image:url(../flags/1x1/to.svg)
}
.fl-tr
{
background-image:url(../flags/4x3/tr.svg)
}
.fl-tr.fls
{
background-image:url(../flags/1x1/tr.svg)
}
.fl-tt
{
background-image:url(../flags/4x3/tt.svg)
}
.fl-tt.fls
{
background-image:url(../flags/1x1/tt.svg)
}
.fl-tv
{
background-image:url(../flags/4x3/tv.svg)
}
.fl-tv.fls
{
background-image:url(../flags/1x1/tv.svg)
}
.fl-tw
{
background-image:url(../flags/4x3/tw.svg)
}
.fl-tw.fls
{
background-image:url(../flags/1x1/tw.svg)
}
.fl-tz
{
background-image:url(../flags/4x3/tz.svg)
}
.fl-tz.fls
{
background-image:url(../flags/1x1/tz.svg)
}
.fl-ua
{
background-image:url(../flags/4x3/ua.svg)
}
.fl-ua.fls
{
background-image:url(../flags/1x1/ua.svg)
}
.fl-ug
{
background-image:url(../flags/4x3/ug.svg)
}
.fl-ug.fls
{
background-image:url(../flags/1x1/ug.svg)
}
.fl-um
{
background-image:url(../flags/4x3/um.svg)
}
.fl-um.fls
{
background-image:url(../flags/1x1/um.svg)
}
.fl-us
{
background-image:url(../flags/4x3/us.svg)
}
.fl-us.fls
{
background-image:url(../flags/1x1/us.svg)
}
.fl-uy
{
background-image:url(../flags/4x3/uy.svg)
}
.fl-uy.fls
{
background-image:url(../flags/1x1/uy.svg)
}
.fl-uz
{
background-image:url(../flags/4x3/uz.svg)
}
.fl-uz.fls
{
background-image:url(../flags/1x1/uz.svg)
}
.fl-va
{
background-image:url(../flags/4x3/va.svg)
}
.fl-va.fls
{
background-image:url(../flags/1x1/va.svg)
}
.fl-vc
{
background-image:url(../flags/4x3/vc.svg)
}
.fl-vc.fls
{
background-image:url(../flags/1x1/vc.svg)
}
.fl-ve
{
background-image:url(../flags/4x3/ve.svg)
}
.fl-ve.fls
{
background-image:url(../flags/1x1/ve.svg)
}
.fl-vg
{
background-image:url(../flags/4x3/vg.svg)
}
.fl-vg.fls
{
background-image:url(../flags/1x1/vg.svg)
}
.fl-vi
{
background-image:url(../flags/4x3/vi.svg)
}
.fl-vi.fls
{
background-image:url(../flags/1x1/vi.svg)
}
.fl-vn
{
background-image:url(../flags/4x3/vn.svg)
}
.fl-vn.fls
{
background-image:url(../flags/1x1/vn.svg)
}
.fl-vu
{
background-image:url(../flags/4x3/vu.svg)
}
.fl-vu.fls
{
background-image:url(../flags/1x1/vu.svg)
}
.fl-wf
{
background-image:url(../flags/4x3/wf.svg)
}
.fl-wf.fls
{
background-image:url(../flags/1x1/wf.svg)
}
.fl-ws
{
background-image:url(../flags/4x3/ws.svg)
}
.fl-ws.fls
{
background-image:url(../flags/1x1/ws.svg)
}
.fl-ye
{
background-image:url(../flags/4x3/ye.svg)
}
.fl-ye.fls
{
background-image:url(../flags/1x1/ye.svg)
}
.fl-yt
{
background-image:url(../flags/4x3/yt.svg)
}
.fl-yt.fls
{
background-image:url(../flags/1x1/yt.svg)
}
.fl-za
{
background-image:url(../flags/4x3/za.svg)
}
.fl-za.fls
{
background-image:url(../flags/1x1/za.svg)
}
.fl-zm
{
background-image:url(../flags/4x3/zm.svg)
}
.fl-zm.fls
{
background-image:url(../flags/1x1/zm.svg)
}
.fl-zw
{
background-image:url(../flags/4x3/zw.svg)
}
.fl-zw.fls
{
background-image:url(../flags/1x1/zw.svg)
}
.fl-ac
{
background-image:url(../flags/4x3/ac.svg)
}
.fl-ac.fls
{
background-image:url(../flags/1x1/ac.svg)
}
.fl-cp
{
background-image:url(../flags/4x3/cp.svg)
}
.fl-cp.fls
{
background-image:url(../flags/1x1/cp.svg)
}
.fl-dg
{
background-image:url(../flags/4x3/dg.svg)
}
.fl-dg.fls
{
background-image:url(../flags/1x1/dg.svg)
}
.fl-ea
{
background-image:url(../flags/4x3/ea.svg)
}
.fl-ea.fls
{
background-image:url(../flags/1x1/ea.svg)
}
.fl-es-ct
{
background-image:url(../flags/4x3/es-ct.svg)
}
.fl-es-ct.fls
{
background-image:url(../flags/1x1/es-ct.svg)
}
.fl-es-ga
{
background-image:url(../flags/4x3/es-ga.svg)
}
.fl-es-ga.fls
{
background-image:url(../flags/1x1/es-ga.svg)
}
.fl-eu
{
background-image:url(../flags/4x3/eu.svg)
}
.fl-eu.fls
{
background-image:url(../flags/1x1/eu.svg)
}
.fl-gb-eng
{
background-image:url(../flags/4x3/gb-eng.svg)
}
.fl-gb-eng.fls
{
background-image:url(../flags/1x1/gb-eng.svg)
}
.fl-gb-nir
{
background-image:url(../flags/4x3/gb-nir.svg)
}
.fl-gb-nir.fls
{
background-image:url(../flags/1x1/gb-nir.svg)
}
.fl-gb-sct
{
background-image:url(../flags/4x3/gb-sct.svg)
}
.fl-gb-sct.fls
{
background-image:url(../flags/1x1/gb-sct.svg)
}
.fl-gb-wls
{
background-image:url(../flags/4x3/gb-wls.svg)
}
.fl-gb-wls.fls
{
background-image:url(../flags/1x1/gb-wls.svg)
}
.fl-ic
{
background-image:url(../flags/4x3/ic.svg)
}
.fl-ic.fls
{
background-image:url(../flags/1x1/ic.svg)
}
.fl-ta
{
background-image:url(../flags/4x3/ta.svg)
}
.fl-ta.fls
{
background-image:url(../flags/1x1/ta.svg)
}
.fl-un
{
background-image:url(../flags/4x3/un.svg)
}
.fl-un.fls
{
background-image:url(../flags/1x1/un.svg)
}
.fl-xk
{
background-image:url(../flags/4x3/xk.svg)
}
.fl-xk.fls
{
background-image:url(../flags/1x1/xk.svg)
}
.fl-xx
{
background-image:url(../flags/4x3/xx.svg)
}
.fl-xx.fls
{
background-image:url(../flags/1x1/xx.svg)
}
