.dark_bb {
    border-bottom: 1px solid var(--border-right);
    background-color: var(--header-color);
    color:var(--font-color);
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
}
.navbar {
    padding: 0.5rem 1rem !important;
  }

  #dark,
#dark header {
    background: #161a27;
   
}
.body{
    font-weight: 400;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
}
.link_icons{
    font-size: 20px !important;
}

.menu_link{
    color:var(--font-color);
    font-weight: 400;
    font-size: 16px;
    text-transform: none;
    margin: 0;
    padding: 17px 5px;
    line-height: 14px;
    position: relative;
    transition: .1s;
  }
  .menu_link:hover,  .menu_link:focus, .menu_link:active {
    color: #e1ab08;
    font-weight: 500;
  }
  
  .menu_body{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    outline: none;
    cursor: default;
    color: #edeff1;
    align-items: center;
    position: relative;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    height: 100%;
  }
  .menu {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: var(--font-color);
    align-items: center;
    pointer-events: none;
    
}
@media screen and (max-width: 767px){
    .firstMobile{
    flex-direction: column;
    pointer-events:auto
 } 
    .baseMobile{
    flex-direction: row;
    justify-content: center;
    pointer-events: auto;
 } 
 .mobileEmpty{
     display:none;
 }
}

@media screen and (min-width: 767px){
.menu {
    pointer-events: auto;
    visibility: visible;
}
}
.menu_dropdown{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    background-color: #1E2329;
    position: absolute;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
    overflow: hidden;
    min-width: 200px;
    max-width: 400px;
    border-radius: 0 0 8px 8px;
    top: 120% !important;
    left: 0;
    padding: 10px;
}
.menu_dropdown .items{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    color: #EAECEF;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    border-radius: 8px;
}

.menu_dropdown .items:hover, .items:focus{
    background-color: #282f37;
}
 .notification{
    left :-350px !important;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    background-color: rgb(30, 35, 41);
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
    overflow: hidden;
    width: 400px;
    padding: 16px 0;
 }
.notification_header{
    cursor: auto;
    box-sizing: border-box;
    margin: 0px 16px 8px;
    min-width: 0px;
    display: flex;
    padding: 12px;
    font-size: 12px;
    background-color: rgb(43, 49, 57);
    border-radius: 4px;
    -webkit-box-align: center;
    align-items: center;

}
.notification_header_summary{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1 0%;
    -webkit-box-align: center;
    align-items: center;
}
.note_header{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 20px;
    color: rgb(234, 236, 239);
}
.note_text{
    box-sizing: border-box;
    margin: 0px 0px 0px 8px;
    min-width: 0px;
    color: rgb(240, 185, 11);
}
.note_click{
    box-sizing: border-box;
    margin: 0px 0px 0px 12px;
    min-width: 0px;
    color: rgb(240, 185, 11);
    cursor: pointer;
}
.note_last{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    text-decoration: none;
    -webkit-box-align: center;
    align-items: center;
    color: var(--link-color)
}
.note_last:hover{
    color: #e1ab08;
}
.note_last_item{
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
}
.notification_body{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    overflow-y: auto;
    max-height: 330px;
    padding: 0 16px;
}
.notification_body_card:hover {
    background-color: rgb(43, 49, 57);
}
.notification_body_card {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 8px;
}
.note_body_left{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    position: relative;
    height: 20px;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(132, 142, 156);
}
.note_body_left_content{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: currentcolor;
    width: 14px;
    height: 14px;
    font-size: 14px;
    fill: currentcolor;
}
.note_body_right{
    box-sizing: border-box;
    margin: 0px 0px 0px 8px;
    min-width: 0px;
    color: rgb(132, 142, 156);
    flex: 1 1 0%;
    font-size: 14px;
}
.note_body_right_title{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: -webkit-box;
    word-break: break-word;
    -webkit-box-orient: vertical;
    color: rgb(234, 236, 239);
    line-height: 20px;
}
.note_body_right_content{
    box-sizing: border-box;
    margin: 4px 0px;
    min-width: 0px;
    display: -webkit-box;
    word-break: break-word;
    -webkit-box-orient: vertical;
}
.note_body_right_time{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 12px;
}
.user_card{
    box-sizing: border-box;
    margin: 0px;
    background-color: rgb(30, 35, 41);
    position: absolute;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
    overflow: hidden;
    border-radius: 0px 0px 8px 8px;
    min-width: 250px;
    left: -200px !important;
    
}
.user_card_head{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding: 16px 0px 16px 16px;
}
.user_card_head_top{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 24px;
    color: rgb(234, 236, 239);
    font-weight: 500;
    font-size: 20px;
}
.user_card_head_buttom{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 12px;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.user_card_head_balance{
    box-sizing: border-box;
    margin: 0px 16px 0px 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(240, 185, 11);
    flex: 1 1 0%;
    font-size: 1.5rem;
}
.user_card_head_verification{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    padding: 4px 8px;
    font-size: 12px;
    flex: 0 0 auto;
    color:  rgb(30, 35, 41);
    -webkit-box-align: center;
    align-items: center;
    border-radius: 100px;
    background-color: rgb(252, 213, 53);
}
.user_card_body{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    border-bottom: 1px solid rgb(39, 42, 46);
}
.user_card_body_link{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    color: rgb(234, 236, 239);
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding: 16px;
}
.user_card_body_link:hover{
    background-color: rgb(43, 49, 57);
    color: rgb(240, 185, 11);
}
.user_card_icon_body{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    
}
.user_card_icons{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: currentColor;
    width: 16px;
    height: 16px;
    font-size: 16px;
    fill: #EAECEF;
    fill: currentColor;
}
.user_card_text_body{
    font-size: 16px;
    margin-left: 12px;
}
.user_card_text_inner{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgb(132, 142, 156);
}
.user_card_qr{
    box-sizing: border-box;
    margin: 8px 0px;
    min-width: 0px;
    display: flex;
    border-bottom: 1px solid rgb(39, 42, 46);
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
}
.user_card_qr_content{
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);

}
.user_card_logout{
    margin-left: 0px;
    color:rgb(30, 35, 41);;
}


  .container{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    margin-top: 70px;
    padding-bottom: 70px;
}
.container_inner{
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    min-height: 600px;
    flex: 1 1 0%;
    flex-direction: column;
}

@media screen and (min-width: 767px)
{
.container_inner{
    flex-direction: row;
}
.container{
    padding-bottom: 0px;
}
}

/* @media screen and (min-width: 992px) {
  .container{
    margin: 0 0 0 200px;
    padding: 20px 30px;
    flex: 1 1 0%;
}
} */

.main_container {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1248px;
}


@media screen and (min-width: 767px){
    .main_container{
    padding-left: 24px;
    padding-right: 24px;
}
}


/* //////////////////////////////////////////////////////////////////////////////// */
.index_welcome {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    align-items: center;
}
@media screen and (min-width: 767px)
{
  .index_welcome {
    padding-top: 40px;
}  
}
@media screen and (min-width: 1023px){
  .index_welcome {
    padding-top: 60px;
}  
}

.index_welcome_left {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-right: 0;
    padding-top: 0;
    width: 100%;
    order: 1;
}

@media screen and (min-width: 767px){
   .index_welcome_left {
    margin-right: 0;
    padding-top: 50px;
} 
.index_welcome_left {
    width: 100%;
    order: 2;
}
}
@media screen and (min-width: 1023px){
    .index_welcome_left {
    margin-right: 106px;
    padding-top: 0;
}
.index_welcome_left {
    width: 486px;
    order: 1;
} 
}



.index_welcome_right {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 0%;
    flex: none;
    order: 2;
}

@media screen and (min-width: 767px){
    .index_welcome_right {
    width: 100%;
    flex: none;
    order: 1;
}
}
@media screen and (min-width: 1023px){
    .index_welcome_right {
    width: auto;
    flex: 1;
    order: 2;
}
}
.left_welcome {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: var(--font-color);
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
}
@media screen and (min-width: 767px)
{.left_welcome {
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;
}
}

.left_welcome_text {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    min-height: 1.2em;
}

.left_welcome_bottom {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-top: 40px;
}
@media screen and (min-width: 767px){
   .left_welcome_bottom {
    margin-top: 48px;
} 
}

.left_welcome_button_content {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    display: block;
    margin-top: 16px;
    align-items: center;
}

@media screen and (min-width: 767px){
.left_welcome_button_content{
    display: flex;
}
}

.index_welcome_right_content {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 0%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 767px){
  .index_welcome_right_content {
    width: 66%;
}  
}
@media screen and (min-width: 1023px){
    .index_welcome_right_content {
    width: 100%;
}
}

.index_welcome_img {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%; 
     position: relative;
    overflow: hidden;
}



.index_list_view {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -8px;
    margin-right: -8px;
    padding-top: 24px;
    padding-bottom: 48px;
}



@media screen and (min-width: 767px){
  .index_list_view {
    margin-left: 0;
    margin-right: 0;
    padding-top: 56px;
    padding-bottom: 56px;
}  
}
@media screen and (min-width: 1023px)
{
   .index_list_view {
    margin-left: -40px;
    margin-right: -40px;
    padding-top: 80px;
    padding-bottom: 80px;
} 
}

.index_list_item {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
}

@media screen and (min-width: 767px){
   .index_list_item {
    width: 50%;
    justify-content: flex-start;
    text-align: center;
} 
}
@media screen and (min-width: 1023px){
   .index_list_item {
    width: 33.33333333%;
    justify-content: flex-start;
    text-align: start;
} 
}

.index_list_item_content {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
}

@media screen and (min-width: 767px){
   .index_list_item_content {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 0;
    padding-right: 0;
} 
}
@media screen and (min-width: 1023px){
  .index_list_item_content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
}  
}
.index_list_item_header {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
}
@media screen and (min-width: 767px){
    .index_list_item_header {
    font-weight: 600;
    font-size: 50px;
    line-height: 48px;
}
}

.index_list_item_body{
    box-sizing: border-box;
    margin:0;
    min-width: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
@media screen and (min-width: 767px){
   .index_list_item_body{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
} 
}
.index_features {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    /* background-color: var(--background-color-lite); */
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 24px 24px 0 0;
    z-index: 2;
}
@media screen and (min-width: 767px){
  .index_features {
    padding-top: 40px;
    padding-bottom: 40px;
}  
}
@media screen and (min-width: 1023px){
    .index_features {
    padding-top: 40px;
    padding-bottom: 40px;
}
}

.index_features_description {
    color: var(--font-color);
    font-size: 20px;
    font-weight: 400;
    line-height: 1.7;
    margin: 0 0 16px;
}

@media (min-width: 768px){
  .index_features_description{
    font-size: 21px;
}  
}
@media (min-width: 1100px){
    .index_features_description {
    font-size: 24px;
}
}














.index_welcome_inner{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 4px;
    flex: 1 1 0%;
    padding: 24px;
}
.index_welcome_content{
    box-sizing: border-box;
    margin: 0px 0px 0px 16px;
    min-width: 0px;
}
.index_welcome_name{
    font-size: 1.2rem;
    font-weight: 400;
    margin: 5px 0;
    line-height: 20px;
}
.index_welcome_last_login{
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1em;
    margin: 0;
   
}
.index_welcome_balance_logo_wrapper{
    display: flex;
    line-height: 0.8rem 
}
.index_welcome_balance_logo_inner{
    background-color: #20c025;
     padding: 10px;
      width: 50px;
       height: 50px;
        border-radius: 50%;
         text-align: center 
}
.index_welcome_balance_logo{
    font-size: 30px; 
    text-align: center 
}
.index_welcome_balance_text_header{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin: 4px 10px 4px 10px;
    font-size: 1rem;
    font-weight: 500;
}
.index_welcome_balance_text{
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    padding: 0;
    margin: -5px 0 0 10px;
}
.index_welcome_balance_currency{
    font-weight: 100;
    font-size: 15px;
    line-height: 40px;
    padding: 0;
    color: var(--font-color-inverse);
}
.index_welcome_verification{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding: 0px 8px;
    font-size: 12px;
    flex: 0 0 auto;
    color: rgb(30, 35, 41);
    align-items: center;
    border-radius: 100px;
    background-color: rgb(252, 213, 53);

}
@media screen and (min-width: 1023px)
{
    .index_banner {
    margin: 16px !important;
}
}


@media screen and (min-width: 767px)
{
    .index_banner {
    margin: 16px !important;
}
}

.index_banner {
    box-sizing: border-box;
    min-width: 0px;
    background: var(--banner-color);
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 8%) 0px 0px 4px;
    border-radius: 10px;
    margin: 10px;
    padding: 24px;
    color:  var(--font-color-inverse);
}
.index_banner_inner{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1 0%;
    padding: 20px;
}
.index_banner_content{
    box-sizing: border-box;
    margin: 0px 0px 0px 16px;
    min-width: 0px;
}
.index_table{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 8px;
}
.card {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    width: 100%;
    padding: 8px;
}
.coin_card:hover{
    opacity: 0.7;
}
@media screen and (max-width: 767px)
{
 .xs {
    width: 50%;
}
}
@media screen and (min-width: 767px)
{
.md, .sm, .lg {
    width: 100%;
}
.xs {
    width: 50%;
}
}
@media screen and (min-width: 1023px)
{
 .lg {
    width: 100%;
}
.md {
    width: 66.6667%;
}
.sm {
    width: 33.3333%
}
.xs {
    width: 16.6667%;
}
}
.card_inner{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    box-shadow: var(--card-shadow);
    border-radius: 4px;
    background-color: var(--card-background-color);
    padding: 0px 16px;
    width: 100%;
}

.card_content{
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
}
.card_header{
   box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    flex: 1 1 0%;
    padding: 16px 0;
    border: 0;
    display: flex;
}
.card_body{
    box-sizing: border-box;
    margin: 0px 0px 10px 0px;
    min-width: 0px;
    position: relative;
}
.card_header_text {
    box-sizing: border-box;
    margin: 0px 16px 0px 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    padding: 0px;
}
@media screen and (min-width: 767px)
{
    .card_header_text {
    font-size: 16px;
}

}

.coin_changes{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
   
}
.coin_ad{
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 10px;
    
}
.coin_ad:hover{
    color: var(--link-color);
}
.coin_id {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    font-size: 12px;
    line-height: 16px;
}
@media screen and (min-width: 767px)
{.coin_id {
    font-size: 16px;
    line-height: 24px;
}
}
.coin_title{
    box-sizing: border-box;
    margin: 0px 4px 0px 0px;
    min-width: 0px;
}
.coin_title_sub{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.coin_price {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #f3911a;
}
.sell  {
    color: rgb(207, 48, 74);
}
.buy  {
    color: rgb(14, 203, 129);
}
@media screen and (min-width: 767px)
{.coin_price {
    font-size: 20px;
    line-height: 32px;
}
}

.coin_equivalent {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 12px;
    line-height: 16px;
}
@media screen and (min-width: 767px)
{
.coin_equivalent { 
    font-size: 14px;
    line-height: 20px;
}

}
.heading_text{
    font-weight: 700;
}
.balance{
    font-weight: 600;
}
.main_balance{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
}
.main_equivalent{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    white-space: pre;
    color: rgb(159 159 159);
    font-weight: 700;
}

.balance_body {
    box-sizing: border-box;
    margin: 0px 0px 12px 0;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
}
@media screen and (min-width: 767px)
{
.balance_body {
    align-items: flex-end;
}
}
.balance_exchanger_icon{
    box-sizing: border-box;
    margin: 0px 8px 0px 16px;
    min-width: 0px;
    font-size: 14px;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    line-height: 1rem;
    color: #ffc107;
}

.card_list {
    box-sizing: border-box;
    margin: 36px 0px 0px;
    min-width: 0px;
    display: flex;
    height: 128px;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}
@media screen and (min-width: 767px)
{
.card_list {
    height: 64px;
    flex-direction: row;
}

}
.display_flex{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
}
.card_list_ctr{
    box-sizing: border-box;
    margin: 0px 0px 0px 24px;
    min-width: 0px;
    display: flex;
    height: 64px;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;

}


.card_list_ctr_title {
    box-sizing: border-box;
    margin: 0px 0px 8px;
    min-width: 0px;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}
@media screen and (min-width: 767px)
{
  .card_list_ctr_title {
    margin-bottom: 8px;
}  
}
.card_list_ctr_info{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgb(112, 122, 138);

}
.card_list_lft{
    box-sizing: border-box;
    margin: 0px;
    
}
.card_list_inner_icon{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: #ffc107;
    font-size: 30px;
    fill: currentcolor;
    width: 1em;
    height: 1em;
}
.main_deposit{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
}
.wallet_btn{
    margin: 0px;
    appearance: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-break: keep-all;
    color: rgb(33, 40, 51);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: #ffc107;
    height: 40px;
    min-width: 100%;
}
@media screen and (min-width: 767px)
{
.wallet_btn{
    min-width: 120px;
}
}
.table{
    overflow-x: auto;
    position: relative;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;

}
.table_head{
    font-size: 12px;
    background-color: rgb(245, 245, 245);
    color: rgb(112, 122, 138);
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}
.table_head_content{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    background-color: inherit;
    height: 40px;
    -webkit-box-align: center;
    align-items: center;
}

.coin_table{
    box-sizing: border-box;
    min-width: 0px;
    color: var(--font-color);
    border-collapse: inherit;
}
.coin_table_coin{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 52px;
}
.coin_table_logo{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}
.coin_table_coin_name{
    box-sizing: border-box;
    margin: 0px 0px 0px 16px;
    min-width: 0px;
    display: flex;
    flex-flow: column wrap;
}
.coin_table_coin_short {
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--font-color)
}
@media screen and (min-width: 767px)
{
.coin_table_coin_short {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
}
@media screen and (min-width: 1023px)
{
    .coin_table_coin_short {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

}
.coin_table_coin_full{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);
}
.modal_coin{
    padding: 0;
    border: 0;
    color: var(--font-color);
    background-color: transparent;
}
.modal_coin_button{
color: var(--font-color);
}
.modal_coin_button:hover{
    color: inherit;
    }

    .modal_coin_button:focus{
        color: inherit;
        box-shadow: none;
        }
.text_input{
    background: var(--background-color);
    border-radius: 0.5rem;
    border: 0;
    color: var(--font-color);
}
.text_input:focus{
    background-color: var( --background-color-lite);
    border: 0;
    color: var(--font-color);
}
    .dropdown_z{
        color: var(--font-color);
        background-color: var(--background-color);
        padding:13px;
        display: flex;
        flex-direction: row;    
        margin: 5px;
        border-radius: 5px;
        width: auto;
    }
    .dropdown_z:hover{
        color: var(--font-color);
        background-color: var(--background-color-lite);
    }
    .dropdown_m{
       border: 0;
       background-color: var(--background-color);
    }
    .coin_deposit_logo{
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
    }
    .coin_deposit_coin_name{
        box-sizing: border-box;
        margin: 0px 0px 0px 10px;
        min-width: 0px;
        display: flex;
        flex-flow: column wrap;
    }
    .coin_deposit_base {
        box-sizing: border-box;
        margin: 0px 0px 12px 0;
        min-width: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex-wrap: wrap;
    }
    @media screen and (min-width: 767px)
    {
    .coin_deposit_base {
        align-items: flex-end;
    }
    }
    .deposit_exchanger_icon{
        box-sizing: border-box;
        margin: 0px 8px 0px 16px;
        min-width: 0px;
        font-size: 10px;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        line-height: 1rem;
        color: #ffc107;
    }
    .deposit_copy_icon{
        box-sizing: border-box;
        font-size: 15px;
        flex-shrink: 0;
        line-height: 1rem;
        color: #ffc107;
    }
    .text_input, .wallet_address:disabled{
        background: var(--background-color);
        border-radius: 0rem;
        border: 0;
        color: var(--font-color);
    }
    .deposit_equivalent{
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        font-size: 14px;
        line-height: 32px;
        white-space: pre;
        font-weight: 600;
        color: rgb(159 159 159);
    }
    .deposit_wallet_text{
        padding: 0 15%;
    text-align: center;
    margin: 10px;
    }
    .text_formated{
        color: #efb509;
        font-style: italic;
    }
    .payment_page_title{
        padding: 10px;
        line-height: 10px;
        display: flex;
        font-size: 14px;
        font-weight: 700;
    }
    .payment_page_amount{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin-left: auto;
    }

    .swap_coin_input{
        display: flex;
        flex-direction: column;
        flex: auto;
    }
    .swap_coin_text_balance{
        padding: 5px;
        line-height: 10px;
        display: flex;
        font-size: 14px;
        font-weight: 700;
    }
    .align_base{
        align-items: baseline;
    }
    .justify_center{
        justify-content: center;
    }
    .swap_coin_equator{
        transform: rotate(90deg);
        font-size: 25px;
    }
    .selectContainer{
        border: none;
        font-size: 15px;
        font-weight: 500;
        background: var(--background-color);
        padding: 10px;
        color:var(--font-color)
    }
    .selectContainer:focus-visible{
        outline: none;
    }
    .footerLink:hover,  .footerLink:focus, .footerLink:active, .footerLink:visited {
        color: #e1ab08;
        font-weight: 600;
      }
      
    