:root{
    --primary:#fb8669;
    --primary-light:#ffac98;
    --secondary:#ff5900;
    --green:rgb(17 215 110);
    --light-green:rgb(79 241 159);
    --transition: ease-in-out 0.25s;
}
    .flex{
        display:flex;
    }
    .flex_wrap{
        flex-wrap:wrap;
    }
    .justify_around{
        justify-content: space-around;
    }
      .justify_between{
        justify-content: space-between;
    }
        .justify_center{
        justify-content: center;
    }
    .items_end{
            align-items: flex-end;
    }
   .flex_row{
       flex-direction:row;
   }
   .flex_col{
       flex-direction:column;
   }
    .balance_card{
         max-width: 100%;
          width:100%;
          box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
          background: var(--gradient-card) !important;
          background-size: 300% 100% !important;
          transition: all 2s ease-in-out;
         
    }
    .balance_card:nth-of-type(2){
        background:var(--banner-color) !important;
        background-size: 300% 100% !important;
        transition: all 2s ease-in-out;
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
    }
     @media (min-width:768px){
        .balance_card{
          
           max-width: 50%;
         width: 40%;
      }
    }
    .amount{
      font-size: 28px;
     font-weight: 700;
    }
    .amount_label{
        color:var(--primary);
     font-size: 12px;
    }
    .display_option:hover{
        color:var(--primary);
        cursor:pointer;
    }
    .primary_btn{
        background:var(--primary);
        transition:var(--transition);
        font-size:12px;
    }
    .primary_btn:hover, .primary_btn:active, .primary_btn:active{
        background:var(--primary-light);
         transition:var(--transition);
    }
    .secondary_btn{
        background:var(--green);
        transition:var(--transition);
        font-size:12px;
    }
     .secondary_btn:hover, .secondary_btn:active, .secondary_btn:active{
        background:var(--light-green);
         transition:var(--transition);
    }
    .mt_2{
        margin-top:8px;
    }
    .text_white{
        color:white !important;
    }
    .btn{
        border:none !important;
    }
    .statsText{
        font-size:14px;
    }
    .well{
        min-height: 20px;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 10px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
        background: var(--card-background-color);
        
    }
    .balance_card:hover{
        background-position: 100% 100% !important;
        transition: all 2s ease-in-out;
    }
    .p_5{
        padding: 20px;
    }

    @property --p{
        syntax: '<number>';
        inherits: true;
        initial-value: 0;
      }
      
      .pie {
        --p:20;
        --b:22px;
        --c:darkred;
        --w:150px;
        
        width:var(--w);
        aspect-ratio:1;
        position:relative;
        display:inline-grid;
        margin:5px;
        place-content:center;
        font-size:25px;
        font-weight:bold;
       
      }
      .pie:before,
      .pie:after {
        content:"";
        position:absolute;
        border-radius:50%;
      }
      .pie:before {
        inset:0;
        background:
          radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
          conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
        -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
                mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
      }
      .pie:after {
        inset:calc(50% - var(--b)/2);
        background:var(--c);
        transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
      }
      .animate {
          animation:p 1s .5s both;
      }
      
      .no_round:before {
        background-size:0 0,auto;
      }
      .no_round:after {
        content:none;
      }
      .animate2 {
          animation:str 1s .5s both;
      }
      @keyframes p {
        from{--p:0}
      }
      @keyframes str {
          from{--progress-width:0}
      }
      .card_head_text{
              align-self: center;
          font-weight: 600;
          margin: 10px 0;
          font-size: 18px;
      }
      .progress{
          background: var(--background-color);
          height: 5px;
          border-radius: 5px;
          overflow: hidden;
          margin-bottom: 20px;
          background-color: #1c1e22;
          box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
      
      }
      
      .prograss_track{
          background: var(--progress);
          height: 5px;
          width: calc(1% * var(--progress-width));
           border-radius: 5px;
      }