.selectContainer{
    border: none;
    font-size: 18px;
    font-weight: 700;
    background: var(--background-color);
    padding: 10px;
    color: var(--font-color);
   /* width: 100px;*/

}

.selectContainer:focus-visible{
    outline: none;
}

