@import url('./uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('./uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('./uicons-brands/css/uicons-brands.css');
/* 1 */
:root {    
--bg-opacity: 1;
--red:255 0 0;
  --green:14 203 129;
  --yellow:255 193 7;
  --white: 250 250 250;
  --blue:5 102 255;
  --cyan: 4 186 250;
  --dark-blue: 0 73 189;
  --gray:102 102 102;
  --orange:233 139 7;
  --orange-dark:239 125 87;
  --orange-darker:222, 68, 68;
  --font-color: #1e1f23;
  --gradient-direction:102.18deg;
  --font-color-inverse: rgb(var(--white) / var(--bg-opacity));
  --background-color: rgb(var(--white) / var(--bg-opacity));
  --background-color-lite: rgb(230, 230, 230);
  --link-color: #1e1f23;
  --banner-color:  linear-gradient(var(--gradient-direction), rgba(var(--orange)), rgba(var(--orange-darker)),  rgba(var(--orange)));
  --session-background:#fff;
  --border-right: 1px solid rgb(234, 236, 239);
  --card-background-color: rgb(255, 255, 255);
  --table-background-color: rgb(251 251 251);
  --card-shadow:rgb(20 21 26 / 4%) 0px 1px 2px, rgb(71 77 87 / 4%) 0px 3px 6px, rgb(20 21 26 / 10%) 0px 0px 1px;
  --table-row-hover: #f6f6f6;
  --warning:rgb(var(--yellow)/var(--bg-opacity));
  --success:rgb(var(--green)/var(--bg-opacity));
  --danger:rgb(var(--red)/ var(--bg-opacity));
  --header-color: rgb(var(--white) / var(--bg-opacity));
  --main: rgb(var(--orange) / var(--bg-opacity));
  --main-alt: rgb(var(--orange-dark) / var(--bg-opacity));
  --text:rgb(71, 77, 87);
  --mini-touch:rgb(201, 201, 201);
  --gradient-card:linear-gradient(30deg, var(--card-background-color), var(--mini-touch), var(--card-background-color))
}

/* 2 */
[data-theme="dark"] {
  --font-color: rgb(var(--white) / var(--bg-opacity));
  --font-color-inverse: #1e1f23;
  --background-color: #17181b;
  --background-color-lite: #292929;
  --link-color: rgb(var(--white) / var(--bg-opacity));
  --session-background:#1e1f23;
  --border-right: 1px solid #373941;
  --card-background-color: rgb(30 31 35);
  --table-background-color: #232428;
  --card-shadow:rgba(181, 190, 236, 0.04) 0px 1px 2px, rgba(166, 180, 206, 0.04) 0px 3px 6px, rgba(217, 221, 243, 0.1) 0px 0px 1px;
  --table-row-hover: rgb(22, 23, 26);
  --header-color: #101113;
  --text:#B7BDC6;
  --mini-touch:rgb(58 58 58);
}
/* 3 */
/* @media screen and (max-width: 767px){
  :root{
    --banner-color: url("../../images/banner_left.png") right top 90px 64px no-repeat, linear-gradient(102.18deg, rgb(168 140 24) 11.81%, rgb(30 31 35) 94.26%);

  }
  [data-theme="dark"] {
    --banner-color:  linear-gradient(102.18deg, rgb(var(--blue)) 11.81%, rgb(153 123 0) 94.26%);
  }
} */
body {
  background-color: var(--background-color);
  color: var(--font-color);
}

a {
  color: var(--link-color);
}
.main-color{
color:var(--main)
}
.main-background{
background-color: var(--main);
color: var(--font-color) ;
}
.main-background:hover{
background-color: var(--main-alt);
}